<template>
	<div style="height: 100%">
		<div v-if="loading" class="loader-container fullscreen">
			<Loader :size="50" :border-width="6" />
		</div>

		<div v-if="!loading && quiz" style="height: 100%">
			<StartScreen v-if="currentQuestion === null && !completed" :quiz="quiz" @start="start" />
			<EndScreen
				v-if="completed"
				:score="score"
				:quiz="quiz"
				:guesses="guesses"
				:elapsed-time="elapsedTime" />
			<div v-if="currentQuestion !== null && !completed" class="sliders">
				<div
					v-for="(question, index) in quiz.questions"
					:id="`question-${index}`"
					:key="index"
					:class="['slide', question.media_type, { visible: currentQuestion === index }]"
					:style="{
						transform:
							currentQuestion === index || currentQuestion > index
								? 'translateX(-120%)'
								: 'translateX(120%)',
					}">
					<Transition name="fade" appear>
						<div v-if="question.media_type === 'image'" class="image-container">
							<ImageComponent :ref="`image-${index}`" :src="question.media_url" />
						</div>
					</Transition>

					<Transition name="fade-slide-up" appear>
						<div class="text-container">
							<h3>{{ question.question }}</h3>

							<div v-if="question.media_type === 'audio'" class="audio-container">
								<Audio :ref="`audio-${index}`" :url="question.media_url" />
							</div>

							<div v-if="question.media_type === 'video'" class="video-container">
								<Video :ref="`video-${index}`" :url="question.media_url" />
							</div>

							<div class="answers">
								<div v-for="(answer, i) in question.options" :key="i" class="radio-input">
									<input
										:id="`question-${index}-answer-${i}`"
										:key="answer"
										v-model="guesses[index].guess"
										type="radio"
										:name="`question-${index}`"
										:value="answer"
										@click="guess($event, answer)" />
									<label :for="`question-${index}-answer-${i}`">{{ answer }}</label>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>

			<div v-if="currentQuestion !== null && !completed" class="step-buttons">
				<div class="timer">
					<div class="inner">
						<Time :time="elapsedTime" />
					</div>
				</div>
				<div class="container">
					<button
						class="secondary icon-left"
						:class="{ disabled: currentQuestion === 0 }"
						:disabled="currentQuestion === 0"
						@click="previous">
						<Icon name="arrow-back" width="22" />
						<span>{{ translate('quiz.previous') }}</span>
					</button>

					<div class="steps">
						<span
							v-for="i in quiz.questions.length"
							:key="i"
							class="step"
							:class="{ active: currentQuestion === i - 1 }"></span>
						<div class="mobile-timer">
							<Time :time="elapsedTime" :icon-width="16" />
						</div>
						<div class="mobile-step">
							<Icon name="question-mark-circle-outline" class="gray" width="16" />
							<span>{{ currentQuestion }} / {{ quiz.questions.length }}</span>
						</div>
					</div>

					<button
						class="icon-right"
						:class="{ disabled: !guesses[currentQuestion] || !guesses[currentQuestion].guess }"
						:disabled="!guesses[currentQuestion] || !guesses[currentQuestion].guess"
						@click="next">
						<span>{{
							currentQuestion === quiz.questions.length - 1
								? translate('quiz.finish')
								: translate('quiz.next')
						}}</span>
						<Icon name="arrow-forward" fill="white" width="22" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { formatTime, shuffleArray, handleError } from '@/utils/utils'

import EndScreen from '@/components/EndScreen.vue'
import StartScreen from '@/components/StartScreen.vue'
import Time from '@/components/Time.vue'
import Loader from '@/components/Loader.vue'
import Audio from '@/components/mediaTypes/Audio.vue'
import Video from '@/components/mediaTypes/Video.vue'
import ImageComponent from '@/components/ImageComponent.vue'
import Icon from '../components/Icon.vue'

export default {
	components: {
		EndScreen,
		StartScreen,
		Time,
		Loader,
		Audio,
		ImageComponent,
		Video,
		Icon,
	},

	data() {
		return {
			quiz: null,
			guesses: [],
			id: null,
			loading: true,
			currentQuestion: null,
			score: 0,
			completed: false,
			startTime: null,
			elapsedTime: 0,
			interval: null,
			currentAttempt: null,
		}
	},

	async beforeMount() {
		this.quiz = await this.getQuiz()
		this.quiz.questions = await this.getQuestions()
		this.quiz.completedBy = await this.getCompletedBy()
		this.currentAttempt = await this.getCurrentAttempt()

		if (this.currentAttempt) {
			if (!this.currentAttempt.completed_at) {
				this.populateGuesses()
				let previousGuesses = await this.getGuesses()
				this.guesses.forEach((guess, index, arr) => {
					arr[index] = { ...guess, ...previousGuesses[index] }
				})
				this.startTime = Date.parse(this.currentAttempt.started_at)
				this.currentQuestion = previousGuesses.length
				this.initTimer()

				setTimeout(() => {
					this.initOldGuesses()
				}, 200)
			} else {
				this.completed = true
				this.guesses = await this.getGuesses()
				this.score = this.guesses.filter((i) => i.correct).length * 100
				this.elapsedTime =
					Date.parse(this.currentAttempt.completed_at) - Date.parse(this.currentAttempt.started_at)
			}
		}

		this.loading = false
	},

	methods: {
		start() {
			this.populateGuesses()
			this.currentQuestion = 0
			this.startTime = new Date()

			// Start Timer
			this.initTimer()

			// Insert new attempt in database
			this.initAttempt()
		},

		initTimer() {
			this.interval = setInterval(() => {
				this.elapsedTime = new Date() - this.startTime
			}, 1000)
		},

		populateGuesses() {
			this.quiz.questions.forEach((question) => {
				this.guesses.push({
					quiz_id: this.quiz.id,
					question_id: question.id,
					user_id: auth.user().id,
					guess: '',
					answer: '',
					correct: null,
				})
			})
		},

		async initAttempt() {
			const { data: attempt, error } = await db.from('attempts').insert([
				{
					quiz_id: this.quiz.id,
					started_at: this.startTime,
				},
			])

			if (error) handleError(error)
			this.currentAttempt = attempt[0]
		},

		async guess(e, answer, input, index) {
			if (index) this.currentQuestion = index

			let inputElement = input || e.target
			let correctAnswer = this.quiz.questions[this.currentQuestion].correct_answer

			this.presentGuessResult({
				inputElement,
				answer,
				correctAnswer,
				questionIndex: this.currentQuestion,
			})

			this.guesses[this.currentQuestion] = {
				...this.guesses[this.currentQuestion],
				guess: answer,
				answer: correctAnswer,
				correct: answer == correctAnswer,
			}

			if (answer === correctAnswer) this.score += 100

			const { error } = await db.from('guesses').insert([
				{
					...this.guesses[this.currentQuestion],
				},
			])

			if (error) handleError(error)
		},

		presentGuessResult({ inputElement, answer, correctAnswer, questionIndex }) {
			document.querySelectorAll(`#question-${questionIndex} .radio-input`).forEach((input) => {
				input.children[1].style.pointerEvents = 'none'
			})

			if (answer === correctAnswer) {
				inputElement.classList.add('correct')
			} else {
				inputElement.classList.add('incorrect')

				setTimeout(() => {
					document
						.querySelector(`.slide#question-${questionIndex} input[value='${correctAnswer}']`)
						?.classList.add('correct')
				}, 200)
			}
		},

		next() {
			this.$refs[`audio-${this.currentQuestion}`]?.[0].pause()
			this.$refs[`video-${this.currentQuestion}`]?.[0].pause()

			if (this.currentQuestion === this.quiz.questions.length - 1) {
				this.complete()
			} else {
				this.currentQuestion++
			}
		},

		previous() {
			this.$refs[`audio-${this.currentQuestion}`]?.[0].pause()
			this.$refs[`video-${this.currentQuestion}`]?.[0].pause()

			if (this.currentQuestion !== 0) {
				this.currentQuestion--
			}
		},

		async complete() {
			clearInterval(this.interval)
			this.completed = true

			const { error } = await db
				.from('attempts')
				.update({ completed_at: new Date() })
				.eq('id', this.currentAttempt.id)

			if (error) handleError(error)
		},

		initOldGuesses() {
			if (this.guesses.length === 0) return

			this.guesses.forEach((guess, index) => {
				if (guess.guess) {
					let correctAnswer = this.quiz.questions[index].correct_answer
					let input = document.querySelector(`input[value="${guess.guess}"]`)
					this.presentGuessResult({
						inputElement: input,
						answer: guess.guess,
						correctAnswer,
						questionIndex: index,
					})
				}
			})
		},

		async getQuiz() {
			const { data: quiz, error } = await db
				.from('quizzes')
				.select('*')
				.eq('qid', this.$route.params.id)
				.limit(1)
				.maybeSingle()

			if (error) handleError(error)
			else return quiz
		},

		async getCompletedBy() {
			const { data: attempts, error } = await db
				.from('attempts')
				.select('*')
				.eq('quiz_id', this.quiz.id)
				.not('completed_at', 'is', null)

			if (error) handleError(error)
			else return attempts.length
		},

		async getQuestions() {
			const { data: questions, error } = await db
				.from('questions')
				.select('*')
				.eq('quiz_id', this.quiz.id)
				.order('order', { ascending: true })

			if (error) handleError(error)
			else
				return questions.map((question) => ({
					...question,
					options: this.shuffleArray(question.options),
				}))
		},

		async getCurrentAttempt() {
			const { data: attempt, error } = await db
				.from('attempts')
				.select('*')
				.match({ quiz_id: this.quiz.id, user_id: auth.user().id })
				.limit(1)
				.maybeSingle()

			if (error) handleError(error)
			return attempt
		},

		async getGuesses() {
			const { data: guesses, error } = await db
				.from('guesses')
				.select('*')
				.match({ quiz_id: this.quiz.id, user_id: auth.user().id })
				.order('question_id', { ascending: true })

			if (error) handleError(error)
			else return guesses
		},

		shuffleArray,
		formatTime,
	},
}
</script>

<style lang="scss" scoped>
$slide-max-width: 700px;

.sliders {
	height: 100%;
	position: relative;
}
.slide {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	opacity: 0;
	padding-bottom: 50px;
	position: absolute;
	text-align: center;
	transition: 0.5s ease;
	width: 100%;

	&.visible {
		opacity: 1;
		transform: translateX(0) !important;
	}

	&.image {
		flex-direction: row;

		h3 {
			text-align: left;
		}
	}

	.image-container {
		padding-right: 50px;

		@include mobile {
			margin-bottom: 10px;
			padding-right: 0;
		}

		::v-deep.image-component {
			align-items: center;
			display: flex;
			justify-content: center;
			max-height: 50vh;

			> img {
				$size: 500px;

				@include lowHeight {
					$size: 250px;
				}

				border-radius: $border-radius * 4;
				display: block;
				height: 100%;
				min-width: $size;
				object-fit: cover;
				width: $size;

				@include mobile {
					height: 150px;
					width: auto;
				}
			}
		}
	}

	.audio-container,
	.video-container {
		margin-top: 25px;
	}

	.text-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		padding-top: 10px;
	}

	h3 {
		display: inline-block;
		font-size: 26px;
		line-height: 1.5;

		@include laptop {
			font-size: 22px;
		}

		@include lowHeight {
			font-size: 20px;
		}

		@include mobile {
			font-size: 18px;
		}
	}

	.answers {
		margin: 50px auto 0;
		width: 100%;

		@include mobile {
			display: block;
			justify-self: flex-end;
			margin-top: 20px;
		}
	}

	.radio-input {
		width: 100%;

		+ .radio-input {
			margin-top: 15px;
		}

		label {
			background: lighten($primary-color, 45%);
			border: 2px solid lighten($primary-color, 30%);
			border-radius: $border-radius;
			cursor: pointer;
			color: $font-color;
			font-size: 16px;
			left: 0;
			line-height: 1.5;
			padding: 15px;
			transition: $transition;

			@include mobile {
				font-size: 14px;
				padding: 10px;
			}

			@include landscape {
				font-size: 14px;
				padding: 10px;
			}

			&:hover {
				background: lighten($primary-color, 40%);
				border: 2px solid lighten($primary-color, 30%);
			}
		}

		input[type='radio'] {
			position: absolute;
			left: -9999px;

			&.correct + label {
				background: $green;
				border-color: $green;
				color: $font-color-contrast;
			}

			&.incorrect + label {
				background: $red;
				border-color: $red;
				color: $font-color-contrast;
			}
		}
	}
}

.step-buttons {
	$background: darken(white, 5%);

	align-items: center;
	background: $background;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	padding: 15px 0;
	position: fixed;
	width: 100%;
	z-index: 2;

	@include mobile {
		padding: 8px 0;
	}

	.container {
		align-items: center;
		display: flex;
		justify-content: space-between;
		max-width: $slide-max-width;
		position: relative;

		@include mobile {
			width: 95%;
		}
	}

	.timer {
		position: absolute;
		top: 0;
		transform: translateY(-100%);
		width: 100px;

		@include mobile {
			display: none;
		}

		.inner {
			align-items: center;
			background: $background;
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			display: flex;
			font-size: 14px;
			font-weight: 500;
			justify-content: center;
			padding: 6px 0;
			position: relative;

			@include mobile {
				font-size: 12px;
			}

			&::before,
			&::after {
				background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><path d='M 0 10 L 10 10 L 10 0 Q 10 10 0 10 Z' fill='%23f2f2f2'></path></svg>");
				bottom: 0;
				content: '';
				height: 10px;
				position: absolute;
				width: 10px;
			}

			&::before {
				left: -10px;
			}

			&::after {
				right: -10px;
				transform: scaleX(-1);
			}
		}

		.icon i {
			opacity: 0.5;
			margin-right: 4px;
		}
	}

	.steps {
		align-items: center;
		display: flex;

		.step {
			$size: 8px;

			background: darken(white, 20%);
			border-radius: $size;
			display: block;
			height: $size;
			transition: $transition;
			width: $size;

			@include mobile {
				display: none;
			}

			+ .step {
				margin-left: 10px;
			}

			&.active {
				background: darken(white, 45%);
			}
		}

		.mobile-step {
			align-items: center;
			color: $font-color;
			display: none;
			font-size: 12px;
			font-weight: 600;

			@include mobile {
				display: flex;
			}

			.icon i {
				margin-right: 6px;
			}
		}

		.mobile-timer {
			display: none;
			font-size: 12px;
			margin-right: 20px;

			@include mobile {
				display: block;
			}
		}
	}

	button {
		justify-content: center;
		width: 130px;

		@include mobile {
			width: 60px;

			span {
				display: none;
			}

			.icon i {
				margin: 0;
			}
		}
	}
}
</style>
