<template>
	<div class="end-screen">
		<Transition name="fade" appear>
			<router-link to="/play" class="back-link">
				<Icon name="arrow-back" />
				<span>{{ translate('back') }}</span>
			</router-link>
		</Transition>

		<Transition name="fade" appear>
			<div
				:class="[
					'content',
					randomCoverImagePattern(),
					{ imageMissing: quiz.cover_url === null || quiz.cover_url === '' },
				]">
				<div class="cover-image">
					<img v-if="quiz.cover_url" :src="quiz.cover_url" alt="Cover Image" />
				</div>

				<div class="inner-content">
					<h2>{{ quiz.title }}</h2>
					<div class="meta">
						<div>
							<p class="made-by">
								{{ translate('madeBy') }} <User :id="quiz.created_by" :clickable="true" />
							</p>
						</div>
						<div>
							<Score v-tippy :override="score" :content="translate('score')" />
						</div>
						<div v-tippy class="elapsed-time" :content="translate('time')">
							<Icon name="clock-outline" class="gray" width="20" />
							<span>{{ formatTime(elapsedTime) }}</span>
						</div>
						<div v-tippy class="correct-answers" :content="translate('result')">
							<Icon name="checkmark-circle-2" class="green" width="20" />
							<span>{{
								`${guesses.filter((g) => g.correct).length}/${quiz.questions.length}`
							}}</span>
						</div>
					</div>
				</div>
			</div>
		</Transition>

		<div class="summary">
			<TransitionGroup appear @before-enter="beforeEnter" @enter="enter">
				<div
					v-for="(question, index) in quiz.questions"
					:key="index"
					class="question"
					:data-index="index"
					:class="guesses[index].correct ? 'correct' : 'incorrect'">
					<div>
						<p class="number">{{ index + 1 }}.</p>
					</div>

					<div>
						<label>{{ translate('quiz.question') }}</label>
						<p class="title">{{ question.question }}</p>
					</div>

					<div>
						<label>{{ translate('quiz.yourAnswer') }}</label>
						<p>{{ guesses[index].guess }}</p>
					</div>

					<div>
						<label>{{ translate('quiz.correctAnswer') }}</label>
						<p>{{ guesses[index].answer }}</p>
					</div>

					<div>
						<Icon
							:name="guesses[index].correct ? 'checkmark-circle-2' : 'close-square'"
							:class="guesses[index].correct ? 'green' : 'red'"
							width="24" />
					</div>
				</div>
			</TransitionGroup>
		</div>
	</div>
</template>

<script>
import { formatTime } from '@/utils/utils'
import gsap from 'gsap'

import Score from '@/components/Score.vue'
import User from '@/components/User.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Score,
		User,
		Icon,
	},

	props: {
		score: {
			type: Number,
			required: true,
		},
		quiz: {
			type: Object,
			required: true,
		},
		guesses: {
			type: Array,
			required: true,
		},
		elapsedTime: {
			type: Number,
			required: true,
		},
	},

	computed: {
		environment() {
			return this.$store.getters.environment
		},
	},

	mounted() {
		if (this.environment !== 'production') {
			console.log({ quiz: this.quiz })
		}
	},

	methods: {
		randomCoverImagePattern() {
			const patterns = ['zigzag3d', 'circles', 'isometric']
			return patterns[Math.floor(Math.random() * patterns.length)]
		},

		formatTime,

		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: el.dataset.index * 0.1,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.end-screen {
	margin: 0 0 100px;
	position: relative;

	.back-link {
		color: $font-color;
		transform: translateY(-200%);
		position: absolute;

		@include mobile {
			display: none;
		}
	}

	.content {
		padding: 50px 50px 25px;
		position: relative;
		text-align: center;

		&.imageMissing {
			&.zigzag3d {
				.cover-image::before {
					background-color: #000000;
					opacity: 0.3;
					background: linear-gradient(135deg, #21212155 25%, transparent 25%) -15px 0/ 30px 30px,
						linear-gradient(225deg, #212121 25%, transparent 25%) -15px 0/ 30px 30px,
						linear-gradient(315deg, #21212155 25%, transparent 25%) 0px 0/ 30px 30px,
						linear-gradient(45deg, #212121 25%, #000000 25%) 0px 0/ 30px 30px;
				}
			}

			&.circles {
				.cover-image::before {
					background-color: #6e6e6e;
					opacity: 0.3;
					background-image: radial-gradient(circle at center center, #000000, #6e6e6e),
						repeating-radial-gradient(
							circle at center center,
							#000000,
							#000000,
							9px,
							transparent 18px,
							transparent 9px
						);
					background-blend-mode: multiply;
				}
			}

			&.isometric {
				.cover-image::before {
					background-color: #000000;
					opacity: 0.3;
					background-image: linear-gradient(
							30deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							150deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							30deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							150deg,
							#212121 12%,
							transparent 12.5%,
							transparent 87%,
							#212121 87.5%,
							#212121
						),
						linear-gradient(
							60deg,
							#21212177 25%,
							transparent 25.5%,
							transparent 75%,
							#21212177 75%,
							#21212177
						),
						linear-gradient(
							60deg,
							#21212177 25%,
							transparent 25.5%,
							transparent 75%,
							#21212177 75%,
							#21212177
						);
					background-size: 30px 53px;
					background-position: 0 0, 0 0, 15px 26px, 15px 26px, 0 0, 15px 26px;
				}
			}
		}
	}

	.cover-image {
		background: white;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;

		&::before {
			background: rgba(black, 0.5);
			border-radius: $border-radius * 2;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		img {
			border-radius: $border-radius * 2;
			height: 100%;
			opacity: 0.5;
			object-fit: cover;
			width: 100%;
		}
	}

	.inner-content {
		top: 40%;
		position: relative;
		z-index: 1;
	}

	h2 {
		color: white;
		font-size: 36px;
		padding-top: 25px;
		text-shadow: -1px -1px 0 $primary-color, 1px -1px 0 $primary-color, -1px 1px 0 $primary-color,
			1px 1px 0 $primary-color, 2px 2px 0 $primary-color, 3px 3px 0 $primary-color;

		@include mobile {
			font-size: 30px;
		}
	}

	.made-by {
		align-items: center;
		display: flex;
		justify-content: center;
		font-size: 14px;
		font-weight: 500;

		::v-deep.user {
			margin-left: 5px;
		}
	}

	.meta {
		align-items: center;
		background: darken(white, 2.5%);
		border: 2px solid darken(white, 7.5%);
		border-radius: $border-radius;
		bottom: 0;
		display: inline-flex;
		font-size: 14px;
		font-weight: 600;
		justify-content: space-between;
		margin: 0 auto 0;
		padding: 10px 25px;
		position: relative;
		transform: translateY(calc(50% + 25px));

		> div {
			align-items: center;
			display: flex;

			+ div {
				margin-left: 35px;
			}

			p {
				margin-left: 5px;
			}

			::v-deep .icon i {
				margin-right: 5px;
			}
		}
	}

	.summary {
		margin-top: 50px;

		.question {
			align-items: center;
			background: darken(white, 10%);
			border-radius: $border-radius;
			display: flex;
			padding: 15px 18px;

			+ .question {
				margin-top: 8px;
			}

			&.correct {
				background: rgba($green, 0.2);
				border: 2px solid rgba($green, 0.4);
			}

			&.incorrect {
				background: rgba($red, 0.2);
				border: 2px solid rgba($red, 0.4);
			}

			> div {
				text-align: left;

				+ div {
					padding: 0 10px;
				}

				&:nth-of-type(1) {
					margin-right: 15px;
				}

				&:nth-last-of-type(1) {
					padding-right: 0;
					text-align: right;
					min-width: 50px;
				}

				&:nth-of-type(2) {
					flex-grow: 1;
				}

				&:nth-of-type(n + 3):nth-of-type(-n + 4) {
					$width: 200px;
					max-width: $width;
					min-width: $width;
				}
			}

			.number {
				font-weight: 600;
			}

			label {
				color: darken(white, 50%);
				font-size: 12px;
				left: 0;
				margin-bottom: 5px;
			}

			.label {
				background: $lightgray;
				border-radius: $border-radius;
				color: $font-color-contrast;
				display: block;
				font-size: 14px;
				padding: 4px 6px;
				position: relative;
				top: 8px;
				text-align: center;
				width: 100%;

				&.correct {
					background: $green;
				}

				&.incorrect {
					background: $red;
				}
			}
		}
	}
}
</style>
